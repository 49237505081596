<template>
  <v-container>
    <div v-if="isloading">
      <spinner></spinner>
    </div>
    <div class="tab-header mb-5">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab"
        :class="{ active: index === selectedTabIndex }"
        style="color: #696F8C;"
      >
      <img src="../../assets/bill.svg" alt="" /> &nbsp;
      <span>{{ index + 1 }}</span>. {{ tab.name }}
    </div>
      <v-btn class="btnClass" @click="closeAccess">
      <img src="../../assets/close-circle.svg" alt="close-circle-icon" />
    </v-btn>
  </div>
    
    <div v-if="selectedTabIndex !== null">
      <h4 class="pl-2" style="color: #101840">Create Access Level</h4>
      <p class="pl-2" style="color: #696F8C;">
        Control your employees access to information, create an access level and
        attaching permissions to it
      </p>
      <v-card flat>
        <keep-alive>
          <component :is="tabs[selectedTabIndex].content"
        ></component>
        </keep-alive>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import CreateAccessLevel from "../AccessLevel/CreateAccessLevel.vue";
import ChoosePermissions from "../AccessLevel/ChoosePermissions.vue";
import AssignAccess from "../AccessLevel/AssignAccess.vue";

export default {
  components: {
    CreateAccessLevel,
    ChoosePermissions,
    AssignAccess,
  },
  data() {
    return {
      isloading: false,
      selectedTabIndex: 0,
      tabs: [
        {
          name: "Basic information",
          content: "CreateAccessLevel",
          route: "/create-access",
        },
        {
          name: "Choose permissions",
          content: "ChoosePermissions",
          route: "/permissions",
        },
        {
          name: "Assign access level",
          content: "AssignAccess",
          route: "/assign-access",
        },
      ],
    };
  },
  
  computed: {
  updateTab() {
      return this.$route.path;
    },
  },
   watch: {
    updateTab(val) {
      this.tabs.forEach((tab, index) => {
        if (val === tab.route) {
          this.selectedTabIndex = index;
        }
      });
    },
  },
  
  methods: {
    selectTab(index) {
      if (this.selectedTabIndex !== index) {
        this.selectedTabIndex = index;
        this.$router.push(this.tabs[index].route);
      }
    },
    closeAccess() {
      this.isloading = true;
      if (this.$route.path !== "/access-level") {
        setTimeout(() => {
          this.isloading = false;
          this.$router.push("/access-level");
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
.tab-header {
  display: flex;
  justify-content: space-around;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
}

.tab.active {
  background-color: #FFF0EA;
  border-radius: 8px;
}

.btnClass {
  background: transparent;
  box-shadow: none;
}
</style>
